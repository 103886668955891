<template>
  <div>
    <div class=" max-w-2xl p-55 mx-auto bg-white mt-2">
      <a-spin tip="Loading" :spinning="dataLoading">
        <div v-if="tradeDetails">
          <div class=" text-base my-2 font-450 text-black-85">请核对以下兑换信息:</div>
          <div class=" grid grid-cols-2 gap-2 my-5  text-base pl-4">
            <div>卖出:</div>
            <div>{{ tradeDetails.client_sell_amount }}  {{ tradeDetails.client_sell_currency }}</div>
            <div>买入:</div>
            <div>{{ tradeDetails.client_buy_amount }}  {{ tradeDetails.client_buy_currency }}</div>
            <div>兑换汇率:</div>
            <div>{{ tradeDetails.client_rate }}</div>
          </div>
          <div v-if="countdown > 0" class=" font-500 text-base text-bl">
            汇率将在 <span class=" text-red-500">{{ countdownMinutes }}分{{ countdownSeconds }}秒</span>后更新
          </div>
        </div>
        <div v-if="errorMessage" class="my-8 font-500 text-lg">Sorry, {{ errorMessage }}</div>
      </a-spin>
      <div>
      </div>
      <div class="flex justify-evenly pt-4">
        <a-button @click="handleCancel" type="danger" >
          上一步
      </a-button>
        <a-button :disabled="dataLoading || !isValueTrade || submitLoading" @click="handleSubmit" type="primary" >
        确认汇兑
      </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getCCRate, getCCConvert } from '@/api/common'
import { message } from 'ant-design-vue'
import { mapState } from 'vuex'
import moment from 'moment'

const rule = {
  reason: [
    { required: true, message: '请输入兑换用途', trigger: 'change' }
  ],
  reference: [
  ]
}
export default {
  components: {
  },
  created () {
    console.log('first')
    console.log(this.convertData)
    this.init()
  },
  data () {
    return {
      rule,
      submitLoading: false,
      dataLoading: false,
      isValueTrade: false,
      errorMessage: '',
      tradeDetails: null,
      countdown: 0,
      timer: null,
      convertRefData: {
        client_buy_amount: null,
        client_origin_buy_amount: null
      }
    }
  },
  props: {
    convertData: {
      type: Object
    }
  },
  methods: {
    init () {
      this.dataLoading = true
      console.log(this.convertData.ConvertDate)
      if (this.convertData.ConvertDate instanceof moment) {
        this.convertData.ConvertDate = this.convertData.ConvertDate.format('YYYY-MM-DD')
      }
      getCCRate({
        currency_pair: this.convertData.sellCurrency + this.convertData.buyCurrency,
        date: this.convertData.ConvertDate,
        amount: this.convertData.amount
      }).then((res) => {
        this.isValueTrade = true
        this.tradeDetails = res
        if (res?.client_buy_amount) {
          this.convertRefData.client_buy_amount = res.client_buy_amount
        }
        if (res?.client_origin_buy_amount) {
          this.convertRefData.client_origin_buy_amount = res.client_origin_buy_amount
        }
        this.SetCountdown()
      }).catch((e) => {
        console.log(e)
        if (e?.data?.error) {
          this.errorMessage = e.data.error
        } else {
          this.errorMessage = 'Something went wrong, please try again later'
        }
      }).finally(() => {
        this.dataLoading = false
      })
    },
    SetCountdown () {
      const timeInterval = 60 * 10
      this.countdown = timeInterval // 10 minutes
      this.timer = setInterval(() => {
        if (this.countdown > 0 && this.countdown <= timeInterval) {
          this.countdown--
        } else if (this.countdown <= 0) {
          clearInterval(this.timer)
          this.init()
        } else {
          clearInterval(this.timer)
        }
      }, 1000)
    },
    handleCancel () {
      this.$emit('StepTwoBack')
      // this.$router.go(-1)
    },
    handleSubmit () {
      this.submitLoading = true
      getCCConvert({ customer_id: this.customer.id, currency_pair: this.convertData.sellCurrency + this.convertData.buyCurrency, amount: this.convertData.amount, ...this.convertRefData })
        .then(
          (res) => {
            console.log('emit')
            this.$emit('StepTwoSucces', res)
            message.success('提交成功')
            // this.$router.replace({ name: 'ConvertList' })
          }
        ).catch((e) => {
          console.log(e.data)
          if (e?.data?.error) {
            message.error(e.data.error)
          } else if (e?.data?.amount) {
            message.error(e.data.amount)
          } else {
            message.error('交易出错')
          }
        }).finally(() => {
          this.submitLoading = false
        })
    }
    // handlessSubmit () {
    //   this.$refs.convertRef.validate(
    //     (valid) => {
    //       if (valid) {
    //       } else {
    //       }
    //     }
    //   )
    // }
  },
  computed: {
    ...mapState('cc', ['customer']),
    countdownMinutes () {
      return Math.floor(this.countdown / 60)
    },
    countdownSeconds () {
      return this.countdown % 60
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>

<style scoped>

</style>
