<template>
  <div>
    <div class=" max-w-2xl mx-auto bg-white mt-2">
        <div v-if="tradeDetails">
          <div class="border-2 p-6 gold_border  rounded-md">
            <div class=" text-lg black95 flex items-center">
              <div class="checkmark mr-4"></div>
              <div>
                货币兑换已发起
              </div>
            </div>
          </div>
          <div class=" text-lg font-450 my-4">
            交易详情:
          </div>
          <div class=" grid grid-cols-2 gap-2   text-base pl-8">
            <div>卖出:</div>
            <div>{{ tradeDetails.client_sell_amount }}  {{ tradeDetails.sell_currency }}</div>
            <div>买入:</div>
            <div>{{ tradeDetails.client_buy_amount }}  {{ tradeDetails.buy_currency }}</div>
            <div>兑换汇率:</div>
            <div>{{ tradeDetails.client_rate }}</div>
            <div>状态:</div>
            <div>{{ tradeDetails.status }}</div>
            <div>创建时间:</div>
            <div>{{ formatedDate(tradeDetails.settlement_date.date) }}</div>
          </div>
          <div class=" text-lg font-450 my-4">
            汇率:
          </div>
          <div class=" grid grid-cols-2 gap-2   text-base pl-8">
            <div>您的兑换汇率:</div>
            <div>{{ tradeDetails.client_rate }}</div>
          </div>
        </div>
      <div>
      </div>
      <div class="flex justify-evenly pt-4">
        <a-button  @click="handleBack" type="primary" >
        返回查看列表
      </a-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  created () {
  },
  data () {
    return {
    }
  },
  props: {
    tradeDetails: {
      type: Object,
      default: () => {
        return {
          client_sell_amount: 0,
          client_buy_amount: 0,
          client_rate: 0,
          sell_currency: '',
          buy_currency: '',
          status: '',
          settlement_date: {
            date: ''
          }
        }
      }
    }
  },
  methods: {
    handleBack () {
      this.$emit('ToList')
    },
    formatedDate (val) {
      if (!val) {
        return
      }
      const date = new Date(val)
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }
      const formatter = new Intl.DateTimeFormat('zh-CN', options)
      return formatter.format(date)
    }
  },
  computed: {
  }
}
</script>

<style scoped>
.gold_border{
  border-color: #bc9a41;
}
</style>
