<template>
  <div class="p-12  max-w-2xl mx-auto bg-white sm:mt-8  mt-0 ">
    <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
