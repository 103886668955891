<template>
  <div>
    <div class=" black95 sm:text-2xl text-lg text-center sm:py-8 py-6 bg-white  border-b border-t title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    }
  }
}
</script>

<style scoped>
.title{
  color: #BC9A41;
}
</style>
