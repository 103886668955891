<template>
  <div>
    <CcHeader title="货币兑换"></CcHeader>
    <div class=" px-12 py-8 max-w-5xl mx-auto">
      <a-steps :current="current">
      <a-step v-for="item in steps" :key="item.title" :title="item.title" />
    </a-steps>
    </div>
    <CCWrapper>
      <div v-show="current == 0">
        <a-spin :spinning="fetchAmountLoading">
        <a-form-model
        id="ConvertFirst"
        ref="ConvertFirst"
        :model="form"
        layout="horizontal"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
        :rules="formRulues"
        >
        <a-form-model-item prop="sellCurrency" class="mt-4 black95 text-sm font-500" label="卖出">
            <a-select
              size="default"
              show-search
              placeholder="选择币种"
              option-filter-prop="children"
              v-model="form.sellCurrency"
              style="width: 100%"
              :filter-option="true"
              :not-found-content="null"
              @select="handleSellCurrencySelect"
            >
              <a-select-option v-for="item in balance" :key="item.currency" :value="item.currency">
              {{ item.currency  }}
            </a-select-option>
            </a-select>
          </a-form-model-item>
          <div v-if="form.sellCurrency">
            <a-row>
              <a-col :span="6">
              </a-col>
              <a-col :span="14">
                <div>账户余额: {{ maxSellAmount }} {{ form.sellCurrency }}</div>
              </a-col>
            </a-row>
          </div>
        <a-form-model-item prop="buyCurrency" class="mt-4 black95 text-sm font-500" label="买入">
            <a-select
              size="default"
              show-search
              placeholder="选择币种"
              option-filter-prop="children"
              v-model="form.buyCurrency"
              style="width: 100%"
              :filter-option="true"
              :not-found-content="null"
            >
            <a-select-option v-for="item in uniqueCurrencies" :key="item.fullname" :value="item.currency">
            {{ item.currency  }}
          </a-select-option>
            </a-select>
          </a-form-model-item>
        <a-form-model-item prop="amount"  class="mt-4 black95 text-sm font-500" label="卖出金额">
          <a-input-number
            v-model="form.amount"
            :step="0.01"
            style="width: 100%"
            :precision="2"
            :max="maxSellAmount"
          />
        </a-form-model-item>
        </a-form-model>
        <div class="flex justify-evenly pt-4">
          <a-button @click="handleCancel" type="danger" >
            取消
        </a-button>
          <a-button @click="handleNext" type="primary" >
          下一步
        </a-button>
        </div>
        </a-spin>
      </div>
      <div v-show="current == 1">
        <div class=" text-base font-450 mb-4 text-black-85">请选择兑换日期:</div>
        <a-form-model
          id="ConvertFirst"
          ref="ConvertFirst"
          :model="form.ConvertDate"
          layout="horizontal"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 14 }"
          :rules="formRulues"
        >
        <a-form-item prop="ConvertDate" label="兑换日期" :disabled-date="disabledDate" has-feedback >
          <a-date-picker
          v-model="form.ConvertDate"
          format="YYYY-MM-DD"
          :disabled-date="disabledDate"
          style="width: 100%" />
          </a-form-item>
        </a-form-model>
        <div class="flex justify-evenly pt-4">
          <a-button @click="handleStepBack" type="danger" >
            上一步
        </a-button>
          <a-button @click="handleStepTwoNext" type="primary" >
          下一步
        </a-button>
        </div>
      </div>
      <div v-if="current == 2">
        <CovertConfirm
        @StepTwoBack="StepTwoBack"
        @StepTwoSucces="StepTwoSucces"
        :convertData="form"></CovertConfirm>
      </div>
      <div v-show="current == 3">
        <ConvertSuccess
        @ToList="handleCancel"
        @Submit="Submit"
        :tradeDetails="successResData"></ConvertSuccess>
      </div>
    </CCWrapper>
  </div>
</template>

<script>
import ccCountryList from '@/assets/cc/ccCountry.json'
import { getCCRealAmount } from '@/api/common'
import moment from 'moment'
import { mapState } from 'vuex'
import CcHeader from '../CCcomponents/CcHeader.vue'
import CCWrapper from '../CCcomponents/CCWrapper.vue'
import CovertConfirm from './CovertConfirm.vue'
import ConvertSuccess from './ConvertSuccess.vue'

const formRulues = {
  sellCurrency: [
    { required: true, message: '请选择货币', trigger: 'change' }
  ],
  buyCurrency: [
    { required: true, message: '请选择货币', trigger: 'change' }
  ],
  ConvertDate: [
    { required: true, message: '请选择日期', trigger: 'change' }
  ],
  amount: [
    { required: true, message: '请输入金额', trigger: 'change' }
  ]
}
export default {
  components: {
    CcHeader,
    CCWrapper,
    CovertConfirm,
    ConvertSuccess
  },
  created () {
    this.ccCountryList = ccCountryList
    this.uniqueCurrencies = this.getUniqueCurrencies()
  },
  data () {
    return {
      fetchAmountLoading: false,
      maxSellAmount: 0,
      current: 0,
      successResData: {},
      steps: [
        {
          title: '选择金额'
        },
        {
          title: '选择时间'
        },
        {
          title: '确认汇率'
        },
        {
          title: '完成'
        }
      ],
      form: {
        sellCurrency: '',
        buyCurrency: '',
        amount: 0,
        ConvertDate: moment()
      },
      formRulues,
      ccCountryList: [],
      uniqueCurrencies: []
    }
  },
  methods: {
    moment,
    StepTwoSucces (res) {
      this.successResData = res
      this.current = 3
    },
    StepTwoBack () {
      this.current = 1
    },
    handleStepTwoNext () {
      // console.log(this.form)
      this.current = 2
    },
    Submit () {

    },
    getUniqueCurrencies () {
      const uniqueCurrenciesSet = new Set()
      return this.ccCountryList.filter(option => {
        if (!uniqueCurrenciesSet.has(option.currency)) {
          uniqueCurrenciesSet.add(option.currency)
          return true
        }
        return false
      })
    },
    formatAmount (value) {
      // 自定义格式化金额显示
      if (!value) return ''
      return `¥ ${value.toFixed(2)}`
    },
    parseAmount (value) {
      // 自定义解析输入值，转换为合法的金额
      const val = value.replace(/[^\d.]/g, '')
      return isNaN(val) ? null : parseFloat(val)
    },
    disabledDate (current) {
      // 获取当前日期的星期几（0 表示星期日，1 表示星期一，以此类推）
      const dayOfWeek = current.day()
      // 禁用星期六（6）和星期日（0）
      return dayOfWeek === 6 || dayOfWeek === 0 || current < moment().startOf('day')

      // return current && current < moment().endOf('day')
    },
    handleStepBack () {
      this.current = 0
    },
    handleCancel () {
      // this.ResetFormAction()
      this.$router.push({ name: 'ConvertList' })
    },
    handleSellCurrencySelect  (value) {
      this.fetchAmountLoading = true
      getCCRealAmount({ customer_id: this.customer.id, currency: value }).then((res) => {
        if (res?.available_balance) {
          if (Number(res.available_balance) <= 0) {
            this.$message.error('余额不足')
            this.maxSellAmount = 0
            return
          }
          this.maxSellAmount = Number(res.available_balance)
        }
      }).finally(() => {
        this.fetchAmountLoading = false
      })
      // const selectedCurrency = this.balance.find(item => item.currency === value)
      // this.maxSellAmount = Number(selectedCurrency.amount)
    },
    handleNext () {
      if (!this.form.sellCurrency || !this.form.buyCurrency) {
        this.$message.error('请填写完整信息')
        return
      }
      if (this.form.sellCurrency === this.form.buyCurrency) {
        this.$message.error('请选择不同币种')
        return
      }
      if (this.form.amount <= 0) {
        this.$message.error('请输入合法金额')
        return
      }
      // if (this.form.ConvertDate instanceof moment) {
      //   this.form.ConvertDate = this.form.ConvertDate.format('YYYY-MM-DD')
      // }
      this.$refs.ConvertFirst.validate(
        (valid) => {
          if (valid) {
            this.current = 1
          } else {
            console.log('not satisafiy')
          }
        }
      )
    }
  },
  computed: {
    beneficiaryStore () {
      return this.$store.state.ccBeneficiary
    },
    ...mapState('cc', ['balance', 'customer'])

  }
}
</script>

<style scoped>

</style>
